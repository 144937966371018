import { createSlice } from "@reduxjs/toolkit";

const deviceActionSlice = createSlice({
  name: "deviceAction",
  initialState: {
    deviceList: [],
  },
  reducers: {
    updateDeviceAction(state, action) {
      state.deviceList = action.payload;
    },
    updateDeviceStatus(state, action) {
      let deviceList = state.deviceList;
      let deviceIndex = deviceList.findIndex(
        (device) => device.deviceId === action.payload.deviceId
      );
      deviceList[deviceIndex].isConnected = action.payload.isConnected;
      state.deviceList = deviceList;
    },
    updateDeviceRunningStatus(state, action) {
      let { deviceList } = state;
      let deviceIndex = deviceList.findIndex(
        (device) => device.deviceId === action.payload.deviceId
      );
      deviceList[deviceIndex].isDeviceStatusLoading = false;
      deviceList[deviceIndex].isRunning = action.payload.isRunning;
      state.deviceList = deviceList;
    },
    updateDeviceLoadingStatus(state, action) {
      let { deviceList } = state;
      deviceList.forEach(
        (device) => (device.isDeviceStatusLoading = false)
      );
      state.deviceList = deviceList;
    },
  },
});

export const deviceActionActions = deviceActionSlice.actions;

export default deviceActionSlice;
