import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { deviceActionActions } from "../../../store/device-action-slice";
import { API_SERVER_ADMIN } from "../../../config/constant";


function isFunction(functionToCheck) {
  return (
    functionToCheck && {}.toString.call(functionToCheck) === "[object Function]"
  );
}

function debounce(func, wait) {
  var timeout;
  var waitFunc;

  return function () {
    if (isFunction(wait)) {
      waitFunc = wait;
    } else {
      waitFunc = function () {
        return wait;
      };
    }

    var context = this,
      args = arguments;
    var later = function () {
      timeout = null;
      func.apply(context, args);
    };
    clearTimeout(timeout);
    timeout = setTimeout(later, waitFunc());
  };
}

const SseHandler = () => {
  let dispatch = useDispatch();
  function getRealtimeData(data) {
    console.log(data, "data");
    if(data.eventName === "device_connected" || data.eventName === "device_disconnected"){
        dispatch(deviceActionActions.updateDeviceStatus({deviceId: data.payload.deviceId, isConnected: data.payload.isConnected}))
    }
    else if(data.eventName === "device_start"){
        dispatch(deviceActionActions.updateDeviceRunningStatus({...data.payload}))
    }
    else if(data.eventName === "device_stop"){
        dispatch(deviceActionActions.updateDeviceRunningStatus({...data.payload}))
    }
    // process the data here,
    // then pass it to state to be rendered
  }
  var reconnectFrequencySeconds = 1;
  var reconnectFunc = debounce(
    function () {
      setupEventSource();
      // Double every attempt to avoid overwhelming server
      reconnectFrequencySeconds *= 2;
      // Max out at ~1 minute as a compromise between user experience and server load
      if (reconnectFrequencySeconds >= 64) {
        reconnectFrequencySeconds = 64;
      }
    },
    function () {
      return reconnectFrequencySeconds * 1000;
    }
  );
  function setupEventSource() {
    const baseUrl = API_SERVER_ADMIN;
    const sseUrl = baseUrl + "sse";
    const sse = new EventSource(sseUrl, {
      withCredentials: false,
    });
    sse.onmessage = (e) => getRealtimeData(JSON.parse(e.data));
    sse.onerror = (e) => {
      // error log here
      console.log(e, "Error");
      sse.close();
      reconnectFunc();
    };
  }
  setupEventSource();
  return <></>;
};

export default SseHandler;
