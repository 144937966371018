import { createSlice } from "@reduxjs/toolkit";

const userSlice = createSlice({
  name: "user",
  initialState: {
    "isLoggedIn": false 
  },
  reducers: {
    updateLoginState(state, action) {
      state.isLoggedIn = action.payload.isLoggedIn;
    }
  },
});

export const userActions = userSlice.actions;

export default userSlice;
