import { createSlice } from "@reduxjs/toolkit";

const siteSlice = createSlice({
  name: "site",
  initialState: {
    siteList: [],
    selectedSite: {},

  },
  reducers: {
    updateSiteList(state, action) {
      state.siteList = action.payload.siteList;
    },
    updateSelectedSite(state, action) {
      state.selectedSite = action.payload.selectedSite;
    }
  },
});

export const siteActions = siteSlice.actions;

export default siteSlice;
