import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import { persistReducer, FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER } from "redux-persist";

import deviceSlice from "./device-slice";
import deviceActionSlice from "./device-action-slice";
import mqttSlice from "./mqtt-slice.js";
import siteSlice from "./site-slice";
import userSlice from "./user-slice";

const persistConfig = {
  key: "counter",
  storage,
};

const reducers = combineReducers({ user: userSlice.reducer, device: deviceSlice.reducer, mqtt: mqttSlice.reducer, site: siteSlice.reducer, deviceAction: deviceActionSlice.reducer });
const persistedReducer = persistReducer(persistConfig, reducers);

export default configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
});
