import { createSlice } from "@reduxjs/toolkit";

const deviceSlice = createSlice({
  name: "device",
  initialState: {
    deviceList: [],
  },
  reducers: {
    updateDeviceList(state, action) {
      state.deviceList = action.payload.deviceList;
    },
    updateDeviceInfo(state, action) {
      let deviceList = state.deviceList;
      let deviceIndex = deviceList.findIndex(
        (device) => device.deviceId === action.payload.deviceId
      );
      deviceList[deviceIndex] = action.payload.deviceInfo;
      state.deviceList = deviceList;
    },
    updateDeviceStatus(state, action) {
      let deviceList = state.deviceList;
      let deviceIndex = deviceList.findIndex(
        (device) => device.deviceId === action.payload.deviceId
      );
      if (action.payload.isConnected)
        deviceList[deviceIndex].isConnected = action.payload.isConnected;
      if (action.payload.isRunning) deviceList[deviceIndex].isRunning = action.payload.isRunning;
      state.deviceList = deviceList;
    },
  },
});

export const deviceActions = deviceSlice.actions;

export default deviceSlice;
