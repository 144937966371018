import { createSlice } from "@reduxjs/toolkit";

const mqttSlice = createSlice({
  name: "mqtt",
  initialState: { client: null, error: null },
  reducers: {
    storeConnection(state, action) {
      console.log("storing connection");
      state.client = action.payload.mqttClient;
    },
    storeError(state, action) {
      state.error = action.payload.error;
    },
  },
});

export const mqttActions = mqttSlice.actions;

export default mqttSlice;
